var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "EmployeeSituation" }, [
    _c("div", { staticClass: "whisper" }, [
      _c("img", {
        staticClass: "whisperIcon",
        attrs: { src: require("@/assets/img/home/weiyu.png"), alt: "" },
      }),
      _vm._v(" " + _vm._s(_vm.whisper) + " "),
      _c("img", {
        staticClass: "whisperBack",
        attrs: { src: require("@/assets/img/home/cloud.png"), alt: "" },
      }),
    ]),
    _c("div", { staticClass: "statistics flex-align-center" }, [
      _c("ul", { staticClass: "statistics-box  flex-just-between" }, [
        _c("li", { staticClass: "statistics-item " }, [
          _c("span", { staticClass: "item-title" }, [_vm._v("在职职工")]),
          _c("span", { staticClass: "item-num" }, [
            _vm._v(_vm._s(_vm.onJobNum || "--")),
            _c("span", { staticClass: "item-unit" }, [_vm._v("人")]),
          ]),
        ]),
        _c("li", { staticClass: "statistics-item " }, [
          _c("span", { staticClass: "item-title" }, [_vm._v("日平均工时")]),
          _c("span", { staticClass: "item-num" }, [
            _vm._v(_vm._s(_vm.averageManHours || "--")),
            _c("span", { staticClass: "item-unit" }, [_vm._v("时")]),
          ]),
        ]),
        _c("li", { staticClass: "statistics-item " }, [
          _c("span", { staticClass: "item-title" }, [_vm._v("上月人均成本")]),
          _c("span", { staticClass: "item-num" }, [
            _vm._v(_vm._s(_vm.averageCostOfLastMonth || "--")),
            _c("span", { staticClass: "item-unit" }, [_vm._v("元")]),
          ]),
        ]),
        _c("li", { staticClass: "statistics-item " }, [
          _c("span", { staticClass: "item-title" }, [_vm._v("审批平均耗时")]),
          _c("span", { staticClass: "item-num" }, [
            _vm._v(_vm._s(_vm.averageTime || "--")),
            _c("span", { staticClass: "item-unit" }, [_vm._v("分")]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }