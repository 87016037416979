// 首页-微语
import {
  LOGIN
} from '../api'
import {
  METHOD,
  request
} from '@/utils/request'

// 获取微语鸡汤
export async function getWhisperContent(params) {
  return request(`${LOGIN}/whisper/getWhisperContent`, METHOD.GET, params)
}

